<template>
  <!-- COPIED FROM THE ORIGINAL WEBSITE -->
  <div class="header-wrapper">
    <div class="home__header">
      <header>
        <div class="logo">
          <h2>
            <a href="http://www.rentgen.bg/">
              Дигитална лаборатория
              <br />
              <span>за дентална образна диагностика</span>
            </a>
          </h2>
        </div>
        <div class="user">
          <a href="http://online.rentgen.bg/">Резултати онлайн</a>
          &nbsp;|&nbsp;
          <!-- <a href="#">Получаване на достъп</a> -->
          <router-link to="/?registration=true">Регистрация дентален лекар</router-link>
        </div>
      </header>
      <nav>
        <ul>
          <li>
            <a href="#">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="home"
                class="svg-inline--fa fa-home fa-w-18"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path
                  fill="currentColor"
                  d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
                />
              </svg>
            </a>
          </li>
          <li>
            <a href="http://www.rentgen.bg/index.php?page=%D0%9E%D0%B1%D0%BE%D1%80%D1%83%D0%B4%D0%B2%D0%B0%D0%BD%D0%B5">Оборудване</a>
          </li>
          <li>
            <a href="http://www.rentgen.bg/index.php?page=%D0%A3%D1%81%D0%BB%D1%83%D0%B3%D0%B8">Услуги</a>
          </li>
          <li>
            <a href="http://www.rentgen.bg/index.php?page=%D0%97%D0%B0+%D0%BF%D0%B0%D1%86%D0%B8%D0%B5%D0%BD%D1%82%D0%B8">За пациенти</a>
          </li>
          <li>
            <a href="http://www.rentgen.bg/index.php?page=%D0%97%D0%B0+%D0%BB%D0%B5%D0%BA%D0%B0%D1%80%D0%B8">За лекари</a>
          </li>
          <li class="active">
            <a href="http://online.rentgen.bg/">Резултати онлайн</a>
          </li>
          <li>
            <a href="http://www.rentgen.bg/index.php?page=%D0%9A%D0%BE%D0%BD%D1%82%D0%B0%D0%BA%D1%82%D0%B8">Контакти</a>
          </li>
        </ul>
      </nav>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {},
  computed: {
    ...mapGetters(['currentUser']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
@import '@/scss/transitions.scss';

@font-face {
  font-family: 'Open Sans';
  src: url('../../assets/fonts/OpenSans-Bold.woff2') format('woff2'),
    url('../../assets/fonts/OpenSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../assets/fonts/OpenSans-SemiBold.woff2') format('woff2'),
    url('../../assets/fonts/OpenSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../assets/fonts/OpenSans-Light.woff2') format('woff2'),
    url('../../assets/fonts/OpenSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../assets/fonts/OpenSans-Regular.woff2') format('woff2'),
    url('../../assets/fonts/OpenSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.clear {
  display: block;
  clear: both;
}

.header-wrapper {
  color: #444;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 13px;
  line-height: 21px;
  margin: 0 0 40px;
  padding: 0;
}
.home__header {
  width: 950px;
  margin: auto;
}
header {
  background: url('../../assets/img/header.png') center center no-repeat;
  height: 157px;
  margin-top: 1px;
  margin-bottom: 10px;
}
.logo {
  float: left;
  margin-right: 25px;
  margin-top: 30px;
  width: auto;
  h2 {
    padding: 0;
    margin: 15px 0;
    font-size: 30px;
    font-weight: 300;
    color: #0d3863;
    line-height: 35px;
    a {
      color: #3f7fa0;
      font-size: 27px;
      padding: 9px 0;
      font-weight: 600;
    }
    &,
    a span {
      color: #777;
      font-size: 19px;
    }
  }
}
.user {
  width: auto;
  margin-top: 15px;
  float: right;
  color: #aaa;
  a {
    color: #0f5f88;
    padding: 2px 3px;
    text-decoration: none;
    &:hover {
      color: #044b70;
      text-decoration: underline;
    }
  }
}

nav {
  float: left;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-color: #ddd #d3d3d3 #c1c1c1 #d3d3d3;
  border-radius: 2px;
  background-image: linear-gradient(
    to bottom,
    #fafafa 0px,
    #efefef 70%,
    #f3f3f3 100%
  );
  box-shadow: 0px 3px 3px -1px #ccc;
  > ul {
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;
    > li {
      display: inline;
      > a {
        display: inline-block;
        color: #777;
        font-size: 15px;
        text-shadow: 1px 1px white;
        border-right: 1px solid #e1e1d1;
        padding: 14px 17px;
        svg {
          display: inline-block;
          height: 14px;
          margin-bottom: -2px;
          width: 14px;
        }
        &:hover {
          background-image: linear-gradient(#eee, white);
          color: #257bc3;
        }
      }
      &.active a {
        text-decoration: none;
        background-color: #3c96c4;
        background-image: linear-gradient(
          to top,
          #297aa4 0px,
          #4ba1cd 100%
        ) !important;
        color: #f9f9f9;
        text-shadow: 1px 1px #0f4d6d;
        box-shadow: 0 3px 9px rgba(6, 100, 159, 0.075) inset;
      }
    }
  }
}
</style>