<template>
  <div class="auth">
    <div class="form-wrapper" v-if="sentEmail">
      <h3 class="center">ЗАБРАВЕНА ПАРОЛА</h3>
      <p class="center">Ще получите инструкции на посочения e-mail адрес!</p>
    </div>
    <div class="form-wrapper" v-else-if="forgottenPassword">
      <h3 class="center">ЗАБРАВЕНА ПАРОЛА</h3>
      <p class="center">Въведете мейла си и ще ви изпратим нова парола</p>
      <form @submit.prevent>
        <div class="icon back" @click="forgottenPassword = false">F</div>
        <input v-model="user.email" type="email" placeholder="e-mail" />
        <Button class="button--bigger round accent" @click="reset">ИЗПРАЩАНЕ</Button>
      </form>
      <p class="center link" @click="forgottenPassword = false">НАЗАД</p>
    </div>
    <div class="form-wrapper" v-else-if="loginMode">
      <h3 class="center">ОНЛАЙН СИСТЕМА ЗА РЕЗУЛТАТИ И НАПРАВЛЕНИЯ</h3>
      <p class="center">Вход за пациенти, дентални лекари и лаборанти</p>
      <form @submit.prevent>
        <input type="text" placeholder="Потребителско име / E-mail" v-model="user.username" />
        <input type="password" placeholder="Парола" v-model="user.password" />
        <button @click="login">ВХОД</button>
      </form>
      <p class="center link" @click="forgottenPassword = true">ЗАБРАВЕНА ПАРОЛА?</p>
    </div>
    <div class="form-wrapper" v-else>
      <template v-if="registered">
        <h3 class="center">УСПЕШНА РЕГИСТРАЦИЯ</h3>
        <p class="center">Благодарим ви за регистрацията в rentgen.bg.</p>
        <p class="center">Ще разгледаме и активираме профила ви до 24ч.</p>
        <p class="center">Ще получите потвърждение на посочения e-mail адрес.</p>
      </template>
      <template v-else>
        <h3 class="center">ОНЛАЙН СИСТЕМА ЗА РЕЗУЛТАТИ И НАПРАВЛЕНИЯ</h3>
        <p class="center">Вход за пациенти, дентални лекари и лаборанти</p>
        <form @submit.prevent>
          <div class="icon back" @click="loginMode = true">F</div>
          <input v-model="user.name" type="text" placeholder="Име, Фамилия" />
          <input v-model="user.email" type="text" placeholder="e-mail" />
          <input
            v-model="user.username"
            type="text"
            placeholder="Потребителско име"
            @keyup="formatUsername()"
          />
          <input v-model="user.password" type="password" placeholder="Парола" />
          <input v-model="user.practice" type="text" placeholder="Име на практика" />
          <input
            v-model="user.location"
            type="text"
            placeholder="Адрес на практика - град, улица №"
          />
          <input v-model="user.website" type="number" placeholder="Уеб страница" />
          <input v-model="user.phone" type="number" placeholder="Телефон" />
          <button @click="register">РЕГИСТРАЦИЯ</button>
        </form>
        <p class="center link" @click="loginMode = true">НАЗАД</p>
      </template>
    </div>
    <template v-if="loginMode && !sentEmail">
      <h3 class="center">ИЛИ СЕ РЕГИСТРИРАЙТЕ, АКО СТЕ ДЕНТАЛЕН ЛЕКАР</h3>
      <p class="center">Веднага след регистрация ще може да издавате направления</p>
      <form @submit.prevent>
        <button class="register" @click="loginMode = false">РЕГИСТРАЦИЯ ДЕНТАЛЕН ЛЕКАР</button>
      </form>
    </template>
    <a
      class="confidentiality"
      :href="`${files}/confidentiality.pdf`"
      target="_blank"
    >ПОВЕРИТЕЛНОСТ НА ИНФОРМАЦИЯТА</a>
  </div>
</template>

<script>
export default {
  computed: {
    files() {
      return process.env.VUE_APP_FILES_URI;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.query.registration === 'true') {
          this.loginMode = false;
        }
      },
    },
  },
  data() {
    return {
      sentEmail: false,
      loginMode: true,
      forgottenPassword: false,
      registered: false,
      user: {
        role: 'dentist',
        username: '',
        email: '',
        password: '',
        location: '',
        name: '',
        phone: '',
      },
    };
  },
  methods: {
    formatUsername() {
      if (this.user.username.match(/[^A-Za-z0-9._]/g)) {
        this.$snotify.warning(
          'За потребителско име използвайте само английски символи, числа, точка или долна черта.'
        );
      }
      this.user.username = this.user.username
        .replace(/[^A-Za-z0-9._]/g, '')
        .toLowerCase();
    },
    toggleLoginMode() {
      this.loginMode = !this.loginMode;

      if (!this.loginMode) {
        this.formatUsername();
      }
    },
    login() {
      if (this.isValid()) {
        this.$store
          .dispatch('login', this.user)
          .then(() => {
            this.$router.push(
              this.$route.query.from ? this.$route.query.from : '/app'
            );
          })
          .catch((err) => {
            this.$snotify.error(err.response.data.message);
          });
      }
    },
    register() {
      if (this.isValid()) {
        this.$apiService
          .post('/auth/register', {
            user: JSON.stringify(this.user),
          })
          .then(() => {
            // this.login();
            this.registered = true;
          })
          .catch((err) => {
            this.$snotify.error(err.response.data.message);
          });
      }
    },
    reset() {
      if (!this.user.email) {
        this.$snotify.error('Не добавили e-mail адрес!');
        return;
      }
      this.$apiService
        .post('/auth/forgotten-password', {
          email: this.user.email,
        })
        .then(() => {
          this.sentEmail = true;
        })
        .catch((err) => {
          this.$snotify.error(err.response.data.message);
        });
    },
    isValid() {
      if (!this.user.username) {
        this.$snotify.error('Трябва да въведете потребителско име!');
        return false;
      }
      if (!this.user.password) {
        this.$snotify.error('Трябва да въведете парола!');
        return false;
      }
      if (!this.loginMode) {
        if (!this.user.name) {
          this.$snotify.error('Трябва да въведете име и фамилия!');
          return false;
        }
        if (!this.user.email) {
          this.$snotify.error('Трябва да въведете e-mail адрес!');
          return false;
        }
      }
      return true;
    },
  },
  mounted() {
    if (this.registerMode) {
      this.toggleLoginMode();
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins.scss';
.auth {
  padding: 10px 10px 100px;
  position: relative;
  min-height: calc(100vh - 258px);
  text-align: center;
}
h3 {
  font-size: 1.384615rem;
  margin-bottom: 6px;
}
.link {
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
  &:hover {
    opacity: 0.8;
  }
}
.form-wrapper {
  align-items: center;
  background-color: #4196c1;
  border-radius: 11px;
  color: #add4ff;
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
  padding: 35px;
  h3 {
    color: #fff;
  }
  form {
    margin-top: 50px;
    margin-bottom: 15px;
    position: relative;
    width: 350px;
    .back {
      align-items: center;
      background-color: #fff;
      border-radius: 50%;
      color: #4196c1;
      cursor: pointer;
      display: inline-flex;
      font-size: 14px;
      font-weight: 700;
      height: 30px;
      justify-content: center;
      left: -50px;
      position: absolute;
      top: 10px;
      width: 30px;
      &:hover {
        opacity: 0.8;
      }
    }
    input {
      background-color: #fff;
      border-radius: 4px;
      border: 0;
      height: 50px;
      margin: 0 0 15px;
      outline: none;
      padding: 0 15px;
      width: 100%;
    }
  }
}
button {
  background-color: #006787;
  border-radius: 4px;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  height: 50px;
  outline: 0;
  width: 100%;
  max-width: 350px;
  &.register {
    background-color: #4196c1;
    margin: 20px 0;
  }
  &:hover {
    opacity: 0.8;
  }
}

.confidentiality {
  bottom: 50px;
  color: #006787;
  font-size: 14px;
  font-weight: 700;
  left: 50%;
  text-align: center;
  text-decoration: underline;
  position: absolute;
  @include transform(translateX(-50%));
}
</style>