<template>
  <div id="app">
    <div class="wrapper">
      <router-view />
    </div>
    <vue-snotify />
  </div>
</template>

<script>
export default {
  watch: {
    $route() {
      this.$store.dispatch('error', null);
    },
  },
  mounted() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap');
@import '~vue-snotify/styles/material.css';

@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-Bold.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-MediumItalic.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-Italic.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-Medium.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-Regular.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'icons';
  src: url('./assets/fonts/icons.eot');
  src: url('./assets/fonts/icons.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/icons.woff') format('woff'),
    url('./assets/fonts/icons.ttf') format('truetype'),
    url('./assets/fonts/icons.svg#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class*='icon-']:before {
  display: inline-block;
  font-family: 'icons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon {
  font-family: 'icons';
}
.roboto {
  font-family: 'Roboto', sans-serif;
}
.roboto-condensed {
  font-family: 'Roboto Condensed', sans-serif;
}

html {
  background: $background;
  box-sizing: border-box;
  color: $color;
  font-size: 13px;
  font-family: 'Google Sans', sans-serif;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}

body {
  margin: 0;
  min-width: 320px;
  padding: 0;

  &.noscroll {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: 0.05rem;
}

.center {
  text-align: center;
}
.right {
  text-align: right;
}

.nowrap {
  white-space: nowrap;
}

.strong {
  font-weight: 700;
}

.error {
  color: $error;
}

.accent {
  color: $accent;
}

.sub {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8rem;
  line-height: 0.8rem;
}

.table-wrapper {
  width: 100%;
  overflow: auto;
  .table {
    width: 100%;
    border-spacing: 0 10px;
    tr {
      td,
      th {
        padding: 10px;
        text-align: left;
        font-size: 0.92308rem;
        .icon {
          vertical-align: middle;
          @include hover();
        }
        &.center {
          text-align: center;
        }
      }
    }
    tbody {
      tr {
        th {
          font-weight: 700;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        td {
          &:first-child {
            border-bottom-left-radius: 6px;
            border-top-left-radius: 6px;
          }
          &:last-child {
            border-bottom-right-radius: 6px;
            border-top-right-radius: 6px;
          }
          background-color: $white--greyish;
          // @include transition(all $transition $cubic);
        }
        &:hover {
          td {
            background-color: #ededed;
          }
        }
        &.row--selected {
          td {
            background-color: #416184;
            color: #fff;
          }
          .sub {
            color: rgba($color: #fff, $alpha: 0.6);
          }
        }
      }
    }
  }
}

@media screen and (max-width: $m) {
  html {
    font-size: 12px;
  }
}

@media screen and (max-width: $sm) {
  .desktop {
    display: none !important;
  }
}
</style>