var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-wrapper"},[_c('div',{staticClass:"home__header"},[_c('header',[_vm._m(0),_c('div',{staticClass:"user"},[_c('a',{attrs:{"href":"http://online.rentgen.bg/"}},[_vm._v("Резултати онлайн")]),_vm._v("  |  "),_c('router-link',{attrs:{"to":"/?registration=true"}},[_vm._v("Регистрация дентален лекар")])],1)]),_c('nav',[_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"}},[_c('svg',{staticClass:"svg-inline--fa fa-home fa-w-18",attrs:{"aria-hidden":"true","focusable":"false","data-prefix":"fas","data-icon":"home","role":"img","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 576 512"}},[_c('path',{attrs:{"fill":"currentColor","d":"M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"}})])])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])]),_c('div',{staticClass:"clear"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('h2',[_c('a',{attrs:{"href":"http://www.rentgen.bg/"}},[_vm._v(" Дигитална лаборатория "),_c('br'),_c('span',[_vm._v("за дентална образна диагностика")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"http://www.rentgen.bg/index.php?page=%D0%9E%D0%B1%D0%BE%D1%80%D1%83%D0%B4%D0%B2%D0%B0%D0%BD%D0%B5"}},[_vm._v("Оборудване")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"http://www.rentgen.bg/index.php?page=%D0%A3%D1%81%D0%BB%D1%83%D0%B3%D0%B8"}},[_vm._v("Услуги")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"http://www.rentgen.bg/index.php?page=%D0%97%D0%B0+%D0%BF%D0%B0%D1%86%D0%B8%D0%B5%D0%BD%D1%82%D0%B8"}},[_vm._v("За пациенти")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"http://www.rentgen.bg/index.php?page=%D0%97%D0%B0+%D0%BB%D0%B5%D0%BA%D0%B0%D1%80%D0%B8"}},[_vm._v("За лекари")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"active"},[_c('a',{attrs:{"href":"http://online.rentgen.bg/"}},[_vm._v("Резултати онлайн")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"http://www.rentgen.bg/index.php?page=%D0%9A%D0%BE%D0%BD%D1%82%D0%B0%D0%BA%D1%82%D0%B8"}},[_vm._v("Контакти")])])
}]

export { render, staticRenderFns }