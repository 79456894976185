<template>
  <div class="home">
    <Header
      :openAuthPanel="openAuthPanel"
      :openAuthPanelAndRegister="openAuthPanelAndRegister"
      @auth-panel-closed="openAuthPanel = false, openAuthPanelAndRegister = false"
    />
    <Auth />
  </div>
</template>

<script>
import Header from './Header';
import Auth from './Auth';
export default {
  name: 'Home',
  components: {
    Header,
    Auth,
  },
  data() {
    return {
      openAuthPanel: false,
      openAuthPanelAndRegister: false,
    };
  },
};
</script>
